import get from 'lodash/get';

import * as Types from './types';

export const Branches = (item: Types.IApi.Branches.Response): Types.IEntity.Branches => {
  const id = get(item, 'id') || 0;

  return ({
    id,
    region: langStrings(get(item, 'region')),
    subRegion: langStrings(get(item, 'subRegion')),
    village: langStrings(get(item, 'village')),
    revokeDate: get(item, 'revokedDate') || '',
    registrationDate: get(item, 'registrationDate') || '',
    status: get(item, 'status') || '',
    address: get(item, 'address') || '',
  });
};



export const Workers = (item: Types.IApi.Workers.Response): Types.IEntity.Workers => ({
  groups: (get(item, 'groups') || []).map(group => Groups(group)),
  position: get(item, 'position') || 0,
  title: langStrings(get(item, 'title')),
  type: get(item, 'position') || ""
});

export const Groups = (item: Types.IApi.Workers.Groups): Types.IEntity.Groups => ({
  fields: (get(item, 'fields') || []).map(field => Fields(field)),
  group_duplicate: get(item, 'group_duplicate') || false,
  position: get(item, 'position') || 0,
  title: langStrings(get(item, 'title'))
})

export const Fields = (item: Types.IApi.Workers.Fields): Types.IEntity.Fields => ({
  description: langStrings(get(item, 'description')),
  duplicable_values: (get(item, 'duplicable_values') || []).map(field => DublicableValues(field)),
  key: get(item, 'key') || "",
  option: langStrings(get(item, 'option')),
  position: get(item, 'position') || 0,
  title: langStrings(get(item, 'title')),
  type: get(item, 'type') || "",
  value: get(item, 'value') || ""
})

export const DublicableValues = (item: Types.IApi.Workers.DublicableValues): Types.IEntity.DublicableValues => ({
  description: langStrings(get(item, 'description')),
  option: langStrings(get(item, 'option')),
  position: get(item, 'position') || 0,
  value: get(item, 'value') || ""
})


export const langStrings = (item?: Types.IApi.Branches.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'currentPage') ? (get(item, 'currentPage') || 0) + 1 : 1,
    total: get(item, 'totalPages') || 1
  };
};