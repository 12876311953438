import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { lazyRetry } from 'helpers';

import { history, http } from 'services';

import { store } from 'store';

import * as AuthModule from 'modules/auth';
import * as NotificationModule from 'modules/notification';
import * as SystemModule from 'modules/system';

import * as Layouts from 'layouts';

import Spinner from 'components/Spinner';
import Splash from 'components/Splash';
import Toast from 'components/Toast';
import ErrorBoundary from 'components/ErrorBoundary';

import 'assets/styles/main.scss';
const KioskFaceAuth = lazy(() => lazyRetry(() => import('pages/Kiosk/FaceID')));
const KioskDashboard = lazy(() => lazyRetry(() => import('pages/Kiosk/Dashboard')));

const Landing = lazy(() => lazyRetry(() => import('pages/Landing')));
const SelectRole = lazy(() => lazyRetry(() => import('pages/SelectRole')));
const Cabinet = lazy(() => lazyRetry(() => import('pages/Cabinet')));
const Auth = lazy(() => lazyRetry(() => import('pages/Auth')));
const Information = lazy(() => lazyRetry(() => import('pages/Information')));
const Application = lazy(() => lazyRetry(() => import('pages/Application')));
const ActHistory = lazy(() => lazyRetry(() => import('pages/History/Act')));
const ApplicationHistory = lazy(() => lazyRetry(() => import('pages/History/Application')));
const CertificateHistory = lazy(() => lazyRetry(() => import('pages/History/Certificate')));
const NewsList = lazy(() => lazyRetry(() => import('pages/News/List')));
const NewsSingle = lazy(() => lazyRetry(() => import('pages/News/Single')));
const Registry = lazy(() => lazyRetry(() => import('pages/Registry')));
const Help = lazy(() => lazyRetry(() => import('pages/Help')));
const HelpView = lazy(() => lazyRetry(() => import('pages/Help/components/Single')));
const StaticPageView = lazy(() => lazyRetry(() => import('pages/StaticPage/Single')));
const OrganizationsList = lazy(() => lazyRetry(() => import('pages/Organizations/List')));
const DocumentPage = lazy(() => lazyRetry(() => import('pages/Document')));
const OrganizationsSingle = lazy(() => lazyRetry(() => import('pages/Organizations/Single')));
const Statistics = lazy(() => lazyRetry(() => import('pages/Statistics')));
const StatisticsUser = lazy(() => lazyRetry(() => import('pages/Statistics/User')));
const Services = lazy(() => lazyRetry(() => import('pages/Services')));
const Verification = lazy(() => lazyRetry(() => import('pages/Verification')));
const CheckDocuments = lazy(() => lazyRetry(() => import('pages/CheckDocuments')));
const Document = lazy(() => lazyRetry(() => import('pages/CheckDocuments/Document')));

// Invoice
const InvoicePage = lazy(() => lazyRetry(() => import('pages/Invoice')));

enum PAGE_TYPE {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

const routes = [
  {
    path: '/',
    component: Landing,
    layout: Layouts.Main
  },
  {
    path: '/invoice/:serial',
    component: InvoicePage,
    layout: Layouts.Main
  },
  {
    path: '/service/:serviceId',
    component: Landing,
    layout: Layouts.Main
  },
  {
    path: '/cabinet',
    component: Cabinet,
    layout: Layouts.Main,
    type: PAGE_TYPE.PRIVATE
  },
  {
    path: '/history/act',
    component: ActHistory,
    layout: Layouts.Main,
    type: PAGE_TYPE.PRIVATE
  },
  {
    path: '/history/application',
    component: ApplicationHistory,
    layout: Layouts.Main,
    type: PAGE_TYPE.PRIVATE
  },
  {
    path: '/information',
    component: Information,
    layout: Layouts.Main
  },
  {
    path: '/application/:applicationId',
    component: Application,
    layout: Layouts.Blank,
    type: PAGE_TYPE.PRIVATE
  },
  {
    path: '/history/certificate',
    component: CertificateHistory,
    layout: Layouts.Main,
    type: PAGE_TYPE.PRIVATE
  },
  {
    path: '/news',
    component: NewsList,
    layout: Layouts.Main
  },
  {
    path: '/news/:id',
    component: NewsSingle,
    layout: Layouts.Main
  },
  {
    path: '/registry/:uid?',
    component: Registry,
    layout: Layouts.Main
  },
  {
    path: '/auth',
    component: Auth,
    layout: Layouts.Blank
  },
  {
    path: '/kiosk',
    component: KioskFaceAuth,
    layout: Layouts.Blank
  },
  {
    path: '/kiosk/applications',
    component: KioskDashboard,
    layout: Layouts.Main
  },
  {
    path: '/kiosk/application/:applicationId',
    component: Application,
    layout: Layouts.Blank,
    type: PAGE_TYPE.PRIVATE
  },
  {
    path: '/help',
    component: Help,
    layout: Layouts.Main
  },
  {
    path: '/help/:id',
    component: HelpView,
    layout: Layouts.Main
  },
  {
    path: '/page/:slug',
    component: StaticPageView,
    layout: Layouts.Main
  },
  {
    path: '/adliya-document/:uuid',
    component: DocumentPage,
    layout: Layouts.Wrapper
  },
  {
    path: '/organizations',
    component: OrganizationsList,
    layout: Layouts.Main
  },
  {
    path: '/organizations/:id',
    component: OrganizationsSingle,
    layout: Layouts.Main
  },
  {
    path: '/statistics',
    component: Statistics,
    layout: Layouts.Main
  },
  {
    path: '/statistics/user',
    component: StatisticsUser,
    layout: Layouts.Main
  },
  {
    path: '/services',
    component: Services,
    layout: Layouts.Main
  },
  {
    path: '/verification/email/:token',
    component: Verification,
    layout: Layouts.Main
  },
  {
    path: '/documents',
    component: CheckDocuments,
    layout: Layouts.Main
  },
  {
    path: '/document/:uuid',
    component: Document,
    layout: Layouts.Main
  }
];

const App = () => {
  return (
    <ErrorBoundary>
      <Provider {...{ store }}>
        <Toast />
        <SystemModule.Containers.LanguageListener />
        <ConnectedRouter {...{ history }}>
          <AuthModule.Containers.Auth>
            {({ isAuthenticated, isFetched, profile }) => (
              <>
                {isFetched ? (
                  <Suspense fallback={<Splash />}>
                    {isAuthenticated && <NotificationModule.Components.Init />}
                    <Layouts.Wrapper>
                      <Switch>
                        {profile.type === 'INDIVIDUAL_ENTREPRENEUR' && (
                          <Route
                            path="/switcher"
                            exact
                            component={() => {
                              return (
                                <Layouts.Blank>
                                  {/* <Suspense fallback={<Spinner />}> */}
                                  <SelectRole />
                                  {/* </Suspense> */}
                                </Layouts.Blank>
                              );
                            }}
                          />
                        )}
                        {routes.map(route => (
                          <Route
                            key={route.path}
                            path={route.path}
                            exact
                            component={() => {
                              if (
                                (isAuthenticated && route.type === PAGE_TYPE.PUBLIC) ||
                                (!isAuthenticated && route.type === PAGE_TYPE.PRIVATE)
                              ) {
                                return <Redirect from="*" to={isAuthenticated ? '/' : '/auth'} />;
                              }

                              return (
                                <route.layout>
                                  <Suspense fallback={<Spinner />}>
                                    <route.component />
                                  </Suspense>
                                </route.layout>
                              );
                            }}
                          />
                        ))}
                        <Redirect from="*" to="/" />;
                      </Switch>
                    </Layouts.Wrapper>
                  </Suspense>
                ) : (
                  <Splash />
                )}
              </>
            )}
          </AuthModule.Containers.Auth>
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>
  );
};

store.subscribe(() => {
  const state = store.getState();
  http.subscribe(state);
});

export default App;
